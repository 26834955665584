const useDevOptions = () => {
  const proxyModeCookie = useCookie('travanto_proxy_mode')

  const useBackendProxyMode = (): void => {
    proxyModeCookie.value = 'backend'
  }
  const useSolrProxyMode = (): void => {
    proxyModeCookie.value = 'solr'
  }

  const searchSystems = useCookie('search_systems', { default: () => [] })

  return {
    proxyMode: proxyModeCookie.value || 'backend',
    useBackendProxyMode,
    useSolrProxyMode,
    searchSystems
  }
}

export default useDevOptions
