<template>
  <RuesBoxSize :size="props.item?.width">
    <div role="search" class="text-left grid lg:grid-cols-6 gap-3">
      <div class="col-span-2">
        <SearchFilterLocation :params="searchParams" :update-on-input="true" @update="update($event)" />
      </div>
      <div class="col-span-2 rues-travel-dates">
        <SearchFilterTravelDates :params="searchParams" @update="update($event)" />
      </div>
      <div class="col-span-2 lg:col-span-1 rues-travel-guests">
        <SearchFilterGuestsBedroomsPets :params="searchParams" @update="update($event)" />
      </div>
      <div class="col-span-2 lg:col-span-1">
        <UiMaskedLink :href="'/search/' + hashParams" :new-tab="false" class="h-full">
          <UiButton color="cta" class="w-full h-full" @click="save">
            <UiLanguagePlaceholder domain="RuesSearch" name="search" />
          </UiButton>
        </UiMaskedLink>
      </div>
    </div>
  </RuesBoxSize>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue'
import { useSearchParamsStore } from '~/stores/searchParams'
import UrlHashSearchParams from '~/utils/search/UrlHashSearchParams'

const searchParamsStore = useSearchParamsStore()
const urlHashSearchParams = new UrlHashSearchParams()
const { currentPortal } = usePortals()
const config = currentPortal.value.search_form
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})

// TODO: Remove this commented-out code:
// searchParamsStore.von = ''
// searchParamsStore.bis = ''
searchParamsStore.resetToDefaultState()

const searchParams = reactive({
  ort: String(props.item?.params?.sf_ort !== undefined && props.item?.params?.sf_ort !== '' ? props.item.params.sf_ort : (config.appearance === 'select' ? props.item?.region || '' : props.item?.region_name || '')),
  personen: 2,
  einzelzimmer: 0,
  ausstattung_haustiere: []
})
const hashParams: ComputedRef<string> = computed(() => {
  const hashParamsString = urlHashSearchParams.convertSearchParamsToHashParamsString(searchParamsStore.activeFilters)
  if (hashParamsString === undefined || typeof hashParamsString !== 'string') {
    return ''
  }
  return hashParamsString
})

const update = (event) => {
  Object.entries(event).forEach(([key, value]) => {
    searchParams[key] = value
  })
}
const save = () => {
  const mergedParams = {
    ...searchParams,
    ...props.item?.params
  }
  searchParamsStore.resetToDefaultState()
  searchParamsStore.$patch(mergedParams)
}
</script>

<style lang="scss">
.rues-travel-dates, .rues-travel-guests{
  &> div{
    @apply w-full;
    margin: 0 !important;
    border: 0 !important;
  }
}
</style>
