<template>
  <UiModalOrDropdown
    ref="modalOrDropdown"
    design-head="min-h-[67px] w-[350px] py-4 px-7"
    design="w-[350px] py-4 px-7"
    :modal-focus="true"
    @update:is-open="updateDropdownState"
    @shown="modalOrDropdownShown()"
    @hidden="modalOrDropdownHidden()"
  >
    <template #trigger>
      <div>
        <div
          role="button"
          tabindex="0"
          :aria-label="t('select_several')"
          :aria-expanded="isDropdownOpen"
          class="active-input focus:outline-petrol-500 focus:outline-offset-0 select-none relative pl-11 pr-6 pt-6 pb-1 font-semibold text-lg border border-neutral-200 bg-white rounded-xl text-left w-full h-[55px] focus:outline-none  custom-select-fontsize cursor-pointer"
          @click="modalOrDropdown.toggle"
          @keydown.enter="modalOrDropdown.toggle"
        >
          <span class="absolute text-petrol-500 text-base font-semibold top-0 left-0 mt-0.5 ml-11">
            {{ t('guests') }}
          </span>
          <span class="absolute top-0 left-0 py-2 ml-3 h-full flex items-center">
            <UiIcon src="/svg/icons/person.svg" width="24" height="24" class="fill-neutral-500" />
          </span>
          <span class="break-all line-clamp-1">
            {{ t(`personen.${numerus(params.personen)}`, { personen: params.personen }) }}
          </span>
          <span class="absolute top-0 right-0 py-2 mr-3 h-full flex items-center">
            <UiIcon src="/svg/icons/select-arrow-uiselect.svg" width="12" height="12" class="fill-neutral-500 custom-select-guests" />
          </span>
        </div>
      </div>
    </template>
    <template #head />
    <template #default>
      <div class="grid gap-x-5 gap-y-5">
        <div class="grid grid-flow-col auto-cols-max place-content-between place-items-center gap-2 text-left">
          <div class="flex items-center">
            <UiIcon src="/svg/icons/person.svg" width="24" height="18" class="fill-neutral-500 inline-block mr-2" />
            <span>{{ t('guests_quantity') }}</span>
          </div>
          <UiQuantityChanger :min="1" :max="99" :quantity-type="t('guests_quantity')" :model-value="params.personen" @update:model-value="updateNumber('personen', $event)" />
        </div>
        <div class="grid grid-flow-col auto-cols-max place-content-between place-items-center gap-2 text-left">
          <div class="flex items-center">
            <UiIcon src="/svg/product-keyfacts/zimmer_und_betten.svg" width="24" height="18" class="fill-neutral-500 inline-block mr-2" />
            <span>{{ t2('quantity_of_bedrooms') }}</span>
          </div>
          <UiQuantityChanger :quantity-type="t2('quantity_of_bedrooms')" :model-value="params.einzelzimmer" @update:model-value="updateNumber('einzelzimmer', $event)" />
        </div>
        <div class="flex justify-start">
          <UiCheckbox
            :value="['kleintiere_erlaubt', 'katzen_erlaubt', 'hunde_alle_erlaubt', 'hunde_kleine_erlaubt']"
            :deactivate-check="params.ausstattung_haustiere?.includes('haustiere_nicht_erlaubt')"
            :model-value="params.ausstattung_haustiere"
            label-position="center"
            design-label="pl-2"
            @update:model-value="update('ausstattung_haustiere', $event)"
          >
            <template #label>
              {{ t2('pets_allowed') }}
            </template>
          </UiCheckbox>
        </div>
      </div>
    </template>
    <template #footerClose>
      <div class="px-3 md:px-0 pb-3 md:pb-0 pt-5">
        <UiButton color="petrol" buttonpadding="w-full md:w-auto px-4 py-3">
          {{ t('apply') }}
        </UiButton>
      </div>
    </template>
  </UiModalOrDropdown>
</template>

<script setup>
const { translate: t, numerus } = useTranslations('SearchFilterGuests')
const t2 = useTranslations('SearchFilters').translate

const emit = defineEmits(['update', 'shown', 'hidden'])

const modalOrDropdown = ref()
const isDropdownOpen = ref(false)

const updateDropdownState = (newState) => {
  isDropdownOpen.value = newState
}

defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
  results: {
    type: Object,
    default: null
  },
  active: {
    type: Boolean,
    default: false
  }
})

const modalOrDropdownShown = () => {
  emit('shown')
}

const modalOrDropdownHidden = () => {
  emit('hidden')
}

const update = (key, val) => {
  emit('update', { [key]: val })
}
const updateNumber = (key, val) => {
  emit('update', { [key]: Number(val) })
}
</script>

<style lang="scss" scoped>
.active .active-input{
  @apply outline-none border-petrol-500 ring-1 ring-petrol-500
}
.active .custom-select-guests{
  @apply rotate-180
}
</style>
